import React, { useState, useEffect } from 'react';
import config from '../config';

const UserManagement = ({ currentUser }) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/users`);
      if (!response.ok) throw new Error('Failed to fetch users');
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      setError('Error fetching users: ' + error.message);
    }
  };

  const handleSelectUser = (user) => {
    setSelectedUser(user);
    setName(user.name);
    setIsAdmin(user.admin);
    setEmail('');
    setPassword('');
    setError('');
    setSuccess('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      let response;
      if (selectedUser) {
        // Updating existing user
        const updateData = { name, admin: isAdmin };
        if (email) updateData.email = email;
        if (password) updateData.password = password;

        response = await fetch(`${config.apiBaseUrl}/api/users/${selectedUser.id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updateData),
        });
      } else {
        // Creating new user
        if (!email || !password) {
          setError('Email and password are required for new users');
          return;
        }
        response = await fetch(`${config.apiBaseUrl}/api/users`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email, name, password, admin: isAdmin }),
        });
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to save user');
      }

      setSuccess('User saved successfully!');
      fetchUsers();
      resetForm();
    } catch (error) {
      setError('Error saving user: ' + error.message);
    }
  };

  const handleDeleteUser = async (userId) => {
    if (userId === currentUser.id) {
      setError("You cannot delete your own account.");
      return;
    }
    
    if (!window.confirm('Are you sure you want to delete this user?')) return;

    try {
      const response = await fetch(`${config.apiBaseUrl}/api/users/${userId}`, {
        method: 'DELETE',
      });

      if (!response.ok) throw new Error('Failed to delete user');

      setSuccess('User deleted successfully!');
      fetchUsers();
      if (selectedUser && selectedUser.id === userId) {
        resetForm();
      }
    } catch (error) {
      setError('Error deleting user: ' + error.message);
    }
  };

  const resetForm = () => {
    setSelectedUser(null);
    setEmail('');
    setName('');
    setPassword('');
    setIsAdmin(false);
    setError('');
    setSuccess('');
  };

  return (
    <div className="user-management-container">
      <div className="users-list">
        <h2>Users</h2>
        <button onClick={resetForm} className="create-new-user-button">Create New User</button>
        {users.map((user) => (
          <div 
            key={user.id} 
            className={`user-item ${selectedUser && selectedUser.id === user.id ? 'selected' : ''} ${currentUser.id === user.id ? 'current-user' : ''}`}
          >
            <div onClick={() => handleSelectUser(user)}>
              <h3>{user.name || 'Unnamed User'}</h3>
              <p>{user.admin ? 'Admin' : 'Regular User'}</p>
              {currentUser.id === user.id && <span className="current-user-indicator">You</span>}
            </div>
            <button 
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteUser(user.id);
              }}
              className="delete-button"
              disabled={currentUser.id === user.id}
            >
              Delete
            </button>
          </div>
        ))}
      </div>
      <div className="user-editor">
        <h2>{selectedUser ? 'Edit User' : 'Create New User'}</h2>
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email{selectedUser ? ' (leave empty to keep current)' : ''}:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={selectedUser ? "New email" : "Email"}
              required={!selectedUser}
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password{selectedUser ? ' (leave empty to keep current)' : ''}:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={selectedUser ? "New password" : "Password"}
              required={!selectedUser}
            />
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={isAdmin}
                onChange={(e) => setIsAdmin(e.target.checked)}
              />
              Admin
            </label>
          </div>
          <button type="submit">
            {selectedUser ? 'Update User' : 'Create User'}
          </button>
          {selectedUser && (
            <button type="button" onClick={resetForm}>Cancel Edit</button>
          )}
        </form>
      </div>
    </div>
  );
};

export default UserManagement;